import { Environments } from '@vaki/shared/util/environment';
// eslint-disable-next-line @nx/enforce-module-boundaries
import packageInfo from './../../../../../package.json';

export const APP_VERSION = `vaki-web@${packageInfo?.version || 'undefined'}`;

export enum Features {
  VAKI_SITES = 'vaki-sites',
  VAKI_GIFTS = 'vaki-gifts',
  VAKI_SPONSORS = 'vaki-sponsors',
  ALGOLIA_RECOMMEND = 'algolia-recommend' // Only available in production
}
export type Feature = `${Features}`;
export type FeatureConfig = {
  [key in Feature]: boolean;
};

export interface Environment {
  env: Environments;
  appVersion: string;
  useEmulators: boolean;
  production: boolean;
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  paypal_mx: {
    client_id: string;
  };
  mercadopago: {
    key: string;
    notification_url: string;
  };
  stripe_key: string;
  stripe_key_mx: string;
  hostUrl: string;
  apiVakiURL: string;
  adminUid: string;
  adminEmail: string;
  froalaKey: string;
  intercom: {
    appId: string;
  };
  algolia: {
    appId: string;
    apiKey: string;
    indexes: {
      vakis: string;
    };
  };
  googleTagManager: {
    id: string;
    auth: string;
    env: string;
  };
  featureFlagsDefault: FeatureConfig;
  recaptcha: {
    siteKey: string;
    appCheckDebugToken?: string;
  };
  siigo: {
    fv_id: number;
    seller_id: number;
    payment_method: number;
    product_id: string;
  };
}
