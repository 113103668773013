import {
  ALGOLIA_API_KEY,
  ALGOLIA_APP_ID,
  ALGOLIA_VAKI_INDEX,
  API_URL,
  APP_ID,
  ENVIRONMENT,
  FEATURE_FLAGS,
  HOST_URL,
  INTERCOM_APP_ID,
  MP_API_KEY,
  MP_NOTIFICATION_URL,
  PAYPAL_MX_CLIENT_ID,
  SIIGO_FV_ID,
  SIIGO_PAYMENT_METHOD,
  SIIGO_PRODUCT_CODE,
  SIIGO_SELLER_ID,
  STRIPE_KEY,
  STRIPE_KEY_MX
} from '@vaki/shared/util/environment';
import { environment } from '../environments/environment';

export const envKeys = [
  { provide: APP_ID, useValue: environment.appVersion },
  { provide: ALGOLIA_API_KEY, useValue: environment.algolia.apiKey },
  { provide: ALGOLIA_APP_ID, useValue: environment.algolia.appId },
  { provide: ALGOLIA_VAKI_INDEX, useValue: environment.algolia.indexes.vakis },
  { provide: HOST_URL, useValue: environment.hostUrl },
  { provide: API_URL, useValue: environment.apiVakiURL },
  { provide: STRIPE_KEY_MX, useValue: environment.stripe_key_mx },
  { provide: STRIPE_KEY, useValue: environment.stripe_key },
  {
    provide: MP_NOTIFICATION_URL,
    useValue: environment.mercadopago.notification_url
  },
  { provide: MP_API_KEY, useValue: environment.mercadopago.key },
  { provide: FEATURE_FLAGS, useValue: environment.featureFlagsDefault },
  { provide: PAYPAL_MX_CLIENT_ID, useValue: environment.paypal_mx.client_id },
  { provide: ENVIRONMENT, useValue: environment.env },
  { provide: INTERCOM_APP_ID, useValue: environment.intercom.appId },
  { provide: SIIGO_FV_ID, useValue: environment.siigo.fv_id },
  { provide: SIIGO_SELLER_ID, useValue: environment.siigo.seller_id },
  { provide: SIIGO_PRODUCT_CODE, useValue: environment.siigo.product_id },
  {
    provide: SIIGO_PAYMENT_METHOD,
    useValue: environment.siigo.payment_method
  }
];
