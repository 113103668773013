import { isPlatformBrowser, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { AfterViewInit, Component, PLATFORM_ID, inject } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { IntercomService } from '@vaki/shared/data-access/intercom';
import { GoogleTagManagerService } from './google-tag-manager';

// 'es' Spanish is our default language
registerLocaleData(localeEs, 'es');
@Component({
  selector: 'vaki-root',
  template: `<router-outlet></router-outlet>`,
  imports: [RouterModule]
})
export class AppComponent implements AfterViewInit {
  private readonly router = inject(Router);
  private gtmService = inject(GoogleTagManagerService);
  private readonly intercomService = inject(IntercomService);
  private platformId = inject(PLATFORM_ID);

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.startLoggingGtagManager();
      this.intercomService.loadIntercom();
    }
  }

  startLoggingGtagManager(): void {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }
}
