{
  "name": "vaki",
  "version": "4.37.8",
  "license": "MIT",
  "browserslist": [
    "> 0.5%",
    "last 2 versions",
    "Firefox ESR",
    "not dead",
    "not IE 11"
  ],
  "scripts": {
    "serve": "nx run vakers-vaki-web:serve",
    "admin:build:dev": "npm run script:models && nx build --project=admin-web-dashboard --configuration=development",
    "admin:build:prod": "npm run script:models && nx build --project=admin-web-dashboard --configuration=production",
    "admin:build:stg": "npm run script:models && nx build --project=admin-web-dashboard --configuration=staging",
    "admin:start:stg": "npm run script:models && npm run admin:build:stg && nx run admin-web-dashboard:serve:staging --port=4201",
    "admin:start": "npm run script:models && npm run admin:build:stg && nx run admin-web-dashboard:serve:development --port=4201",
    "admin:deploy:dev": "firebase use dev && firebase deploy --only hosting admin",
    "admin:deploy:prod": "firebase use prod && firebase deploy --only hosting admin",
    "affected:apps": "nx affected:apps",
    "affected:build": "nx affected:build",
    "affected:dep-graph": "nx affected:dep-graph",
    "affected:e2e": "nx affected:e2e",
    "affected:libs": "nx affected:libs",
    "affected:lint": "nx affected:lint",
    "affected:test": "nx affected:test",
    "affected": "nx affected",
    "build": "npm run vaki-web:build:dev && npm run admin:build:dev && npm run vaki-link:build",
    "clean:build": "rm -rf dist && cd public && rm -rf dist && cd ..",
    "clean:cache": "npm cache clean --force && rm -rf node_modules package-lock.json && cd functions && rm -rf node_modules package-lock.json && cd .. && cd public && rm -rf node_modules package-lock.json && cd ..",
    "clean": "npm run clean:cache && npm run clean:build",
    "dep-graph": "nx dep-graph",
    "e2e:browser:chrome": "nx run vakers-vaki-web-e2e:e2e:staging --browser chrome --no-exit",
    "e2e:ci": "npm run script:models && nx run vakers-vaki-web-e2e:e2e:staging --headless --record --key=$npm_config_key",
    "e2e:config": "node tools/scripts/generate-cypress-config.js",
    "e2e:local": "nx run vakers-vaki-web-e2e:e2e:es --headless",
    "e2e": "npm run e2e:config && npm run script:models && nx run vakers-vaki-web-e2e:e2e:es --headless",
    "emulators:start": "npm run functions:getconfig && cd functions && npm run build:watch | firebase emulators:start --import ./../.firebase/firestore-backup",
    "functions:getconfig": "cd functions && firebase functions:config:get > .runtimeconfig.json && cd ..",
    "firebase:start:functions": "npm run firebase:getconfig && cd functions && npm run build:watch && cd .. && firebase emulators:start --only functions",
    "format:check": "nx format:check",
    "format:write": "nx format:write",
    "functions:build:dev": "cd functions && npm i && npm run build && cd ../",
    "functions:build:prod": "cd functions && npm i && npm run build --prod && cd ../",
    "functions:install": "cd functions && npm i && cd ..",
    "gcloud:credential:dev": "export GOOGLE_APPLICATION_CREDENTIALS=\"/Users/ni500/.config/gcloud/vaquinha-dev-firebase-adminsdk-96o9p-473a4b37ed.json\"",
    "gcloud:credential:prod": "export GOOGLE_APPLICATION_CREDENTIALS=\"/Users/ni500/.config/gcloud/vaquinha-a55b7-firebase-adminsdk-1yv4r-11a117a2ba.json\"",
    "help": "nx help",
    "husky:install": "husky install",
    "i18n:en": "nx extract-i18n vakers-vaki-web --output-path libs/vakers/locale/util/src/lib --out-file messages.en.xlf",
    "i18n": "nx extract-i18n vakers-vaki-web --output-path libs/vakers/locale/util/src/lib",
    "lint:functions": "cd functions && npm run lint && cd ../",
    "lint": "nx run lint --fix && npm run script:models && nx affected:lint --fix && npx stylelint \"{apps,libs}/**/src/**/*.scss\" && npm run lint:functions",
    "ng": "nx",
    "nx": "nx",
    "postbuild": "npm run sourcemap:move && npm run script:vakers-vaki-web",
    "postinstall": "npm run script:packages && npm run vaki-web:install:public-dendencies && npm run functions:install && npm run husky:install",
    "precommit": "npm run script:models",
    "prerender": "nx run vakers-vaki-web:prerender",
    "script:download-firestore": "node tools/scripts/query-builder.js",
    "script:models": "node tools/scripts/shared-models.js",
    "script:packages": "node tools/scripts/update-packages.js",
    "script:update-fields-firestore": "node tools/scripts/update-field-firestore/update-field-firestore.js",
    "script:vakers-vaki-web": "node tools/scripts/vakers-vaki-web.js",
    "sourcemap:vaki-web:main": "source-map-explorer dist/apps/vakers/vaki-web/browser/es/main-es2015.js",
    "sourcemap:vaki-web": "source-map-explorer dist/apps/vakers/vaki-web/browser/es/*.js",
    "sourcemap": "source-map-explorer public/dist/apps/vakers/vaki-web/server/es/*.js",
    "sourcemap:move": "rm -rf mkdir dist/apps/vakers/vaki-web/sourceMaps && mkdir dist/apps/vakers/vaki-web/sourceMaps && mv dist/apps/vakers/vaki-web/server/es/*.map dist/apps/vakers/vaki-web/sourceMaps && mv dist/apps/vakers/vaki-web/server/en/*.map dist/apps/vakers/vaki-web/sourceMaps",
    "storage:cors:dev": "gsutil cors set tools/storage-cors/dev-cors.json gs://vaquinha-dev.appspot.com",
    "test": "nx test",
    "update": "nx migrate latest",
    "vaki-link:build": "nx run vakers-vaki-link:build",
    "vaki-link:deploy:prod": "gcloud config set project vaquinha-a55b7 && gcloud run deploy vakers-vaki-link --source=dist/apps/vakers/vaki-link --region=us-central1 --service-account=firebase-adminsdk-1yv4r@vaquinha-a55b7.iam.gserviceaccount.com",
    "vaki-link:deploy:stg": "gcloud config set project vaquinha-dev && gcloud run deploy vaki-link --source=dist/apps/vakers/vaki-link --region=us-central1 --service-account=firebase-adminsdk-96o9p@vaquinha-dev.iam.gserviceaccount.com",
    "vaki-link:start": "nx run vakers-vaki-link:serve",
    "vaki-web:build:dev": "npm run script:models && nx build --project=vakers-vaki-web --configuration=development  && npm run postbuild",
    "vaki-web:build:prod": "npm run script:models && nx build --project=vakers-vaki-web --configuration=production && npm run postbuild",
    "vaki-web:build:stg": "npm run script:models && nx build --project=vakers-vaki-web --configuration=staging && npm run postbuild",
    "vaki-web:deploy:prod": "npm run vaki-web:build:prod && gcloud config set project vaquinha-a55b7 && gcloud run deploy vaki-web --service-account=firebase-adminsdk-1yv4r@vaquinha-a55b7.iam.gserviceaccount.com --source=public --region=us-central1 --project vaquinha-a55b7",
    "vaki-web:deploy:stg": "npm run vaki-web:build:stg && gcloud config set project vaquinha-dev && gcloud run deploy vaki-web --service-account=firebase-adminsdk-96o9p@vaquinha-dev.iam.gserviceaccount.com --source=public --region=us-central1 --project vaquinha-dev --revision-suffix=''",
    "vaki-web:start:en": "npm run script:models && nx serve --project=vakers-vaki-web --configuration=en --port 4202",
    "vaki-web:start:prod": "npm run script:models && nx serve --project=vakers-vaki-web --configuration=production",
    "vaki-web:start:stg": "npm run script:models && nx serve --project=vakers-vaki-web --configuration=staging --port 4202",
    "vaki-web:start:ssr": "npm run vaki-web:build:stg  && node --trace-warnings public/index.js",
    "vaki-web:start": "npm run script:models && nx serve --project=vakers-vaki-web --configuration=development",
    "vaki-web:install:public-dendencies": "cd public && npm i && cd ..",
    "workspace-generator": "nx workspace-generator"
  },
  "private": true,
  "dependencies": {
    "@algolia/client-search": "^5.10.0",
    "@angular/animations": "19.1.4",
    "@angular/cdk": "19.1.2",
    "@angular/common": "19.1.4",
    "@angular/compiler": "19.1.4",
    "@angular/core": "19.1.4",
    "@angular/elements": "19.1.4",
    "@angular/fire": "19.0.0",
    "@angular/forms": "19.1.4",
    "@angular/localize": "19.1.4",
    "@angular/material": "19.1.2",
    "@angular/platform-browser": "19.1.4",
    "@angular/platform-browser-dynamic": "19.1.4",
    "@angular/platform-server": "19.1.4",
    "@angular/pwa": "19.1.5",
    "@angular/router": "19.1.4",
    "@angular/service-worker": "19.1.4",
    "@angular/ssr": "19.1.5",
    "@google-cloud/bigquery": "^5.12.0",
    "@google-cloud/storage": "^5.15.0",
    "@hubspot/api-client": "^8.9.0",
    "@intercom/messenger-js-sdk": "^0.0.14",
    "@kolkov/angular-editor": "^3.0.0-beta.0",
    "@nestjs/axios": "^3.0.2",
    "@nestjs/common": "^10.3.8",
    "@nestjs/core": "^10.3.8",
    "@nestjs/platform-express": "^10.3.8",
    "@nx/angular": "20.4.0",
    "@nx/devkit": "20.4.0",
    "@nx/plugin": "20.4.0",
    "@schematics/angular": "19.1.5",
    "@sendgrid/mail": "^8.1.3",
    "@stripe/stripe-js": "^2.1.6",
    "@swc/helpers": "0.5.12",
    "axios": "^1.6.0",
    "chart.js": "^4.4.6",
    "compression": "^1.7.4",
    "core-js": "^3.29.0",
    "cross-fetch": "^3.1.5",
    "express": "4.21.2",
    "firebase": "^11.2.0",
    "firebase-admin": "^13.1.0",
    "firebase-functions": "^6.0.1",
    "fs-extra": "^10.0.0",
    "g": "^2.0.1",
    "html-to-image": "^1.8.5",
    "image-size": "^1.0.0",
    "imagemin": "^7.0.1",
    "imagemin-jpegtran": "^7.0.0",
    "imagemin-mozjpeg": "^9.0.0",
    "imagemin-pngquant": "^9.0.2",
    "intl": "^1.2.5",
    "json2csv": "^5.0.6",
    "lodash-es": "^4.17.21",
    "luxon": "^3.4.0",
    "md5": "^2.3.0",
    "mercadopago": "^1.5.8",
    "ngx-cookie": "6.0.1",
    "ngx-material-timepicker": "13.1.1",
    "ngx-paypal": "^11.0.0",
    "ngx-quicklink": "^0.2.7",
    "node-fetch": "^2.6.1",
    "pdfmake": "^0.2.4",
    "pug": "^3.0.2",
    "reflect-metadata": "^0.1.13",
    "request": "^2.88.2",
    "rxjs": "^7.5.6",
    "search-insights": "^2.16.0",
    "sharp": "^0.29.3",
    "stripe": "14.3.0",
    "swiper": "^11.1.3",
    "tslib": "^2.3.0",
    "uuid": "^8.3.2",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.1.5",
    "@angular-devkit/core": "19.1.5",
    "@angular-devkit/schematics": "19.1.5",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/compiler-cli": "19.1.4",
    "@angular/language-service": "19.1.4",
    "@commitlint/cli": "^19.3.0",
    "@nestjs/schematics": "^10.0.1",
    "@nestjs/testing": "^10.3.8",
    "@nx/cypress": "20.4.0",
    "@nx/eslint": "20.4.0",
    "@nx/eslint-plugin": "20.4.0",
    "@nx/express": "20.4.0",
    "@nx/jest": "20.4.0",
    "@nx/js": "20.4.0",
    "@nx/nest": "^20.4.0",
    "@nx/node": "20.4.0",
    "@nx/web": "20.4.0",
    "@nx/webpack": "20.4.0",
    "@nx/workspace": "20.4.0",
    "@pmmmwh/react-refresh-webpack-plugin": "^0.5.7",
    "@svgr/webpack": "^8.0.1",
    "@swc-node/register": "1.9.2",
    "@swc/cli": "0.6.0",
    "@swc/core": "1.5.7",
    "@types/compression": "^1.7.2",
    "@types/core-js": "^2.5.5",
    "@types/estree": "^1.0.0",
    "@types/express": "4.17.21",
    "@types/fs-extra": "^9.0.13",
    "@types/imagemin-jpegtran": "^5.0.4",
    "@types/intl": "^1.2.2",
    "@types/jest": "29.5.14",
    "@types/json2csv": "^5.0.3",
    "@types/lodash-es": "^4.17.4",
    "@types/md5": "^2.3.1",
    "@types/node": "^20.5.7",
    "@types/node-fetch": "^3.0.3",
    "@types/pdfmake": "^0.1.20",
    "@types/pug": "^2.0.5",
    "@types/sharp": "^0.29.2",
    "@types/stripe-v3": "^3.1.30",
    "@types/uuid": "^8.3.1",
    "@typescript-eslint/eslint-plugin": "7.18.0",
    "@typescript-eslint/parser": "7.0.0",
    "@typescript-eslint/utils": "7.18.0",
    "browser-sync": "^3.0.3",
    "cypress": "13.13.2",
    "cypress-file-upload": "^5.0.8",
    "dotenv": "~10.0.0",
    "eslint": "8.57.0",
    "eslint-config-google": "^0.14.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-cypress": "2.15.1",
    "eslint-plugin-import": "^2.27.5",
    "exitzero": "^1.0.1",
    "firebase-functions-test": "^3.3.0",
    "husky": "^7.0.2",
    "jasmine-marbles": "~0.9.1",
    "jest": "29.7.0",
    "jest-environment-jsdom": "29.7.0",
    "jest-environment-node": "^29.4.1",
    "jest-preset-angular": "14.4.2",
    "jsonc-eslint-parser": "^2.1.0",
    "nx": "20.4.0",
    "prettier": "2.8.3",
    "react-refresh": "^0.10.0",
    "source-map-explorer": "^2.5.3",
    "stylelint": "^13.13.1",
    "stylelint-config-prettier": "^8.0.2",
    "stylelint-config-standard": "^22.0.0",
    "stylelint-prettier": "^1.2.0",
    "stylelint-scss": "^3.21.0",
    "ts-jest": "29.1.1",
    "ts-node": "10.9.1",
    "typescript": "5.7.3",
    "webpack-bundle-analyzer": "^4.5.0",
    "ws": "^8.12.0",
    "xmlhttprequest": "^1.8.0"
  },
  "overrides": {
    "undici": "6.19.2",
    "undici-types": "6.19.2"
  }
}
