import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  ReCaptchaEnterpriseProvider,
  initializeAppCheck,
  provideAppCheck
} from '@angular/fire/app-check';
import { environment } from '../../environments/environment';
import { GoogleTagManagerModule } from '../google-tag-manager';

export const browserConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      GoogleTagManagerModule.forRoot({
        id: environment.googleTagManager.id,
        gtm_auth: environment.googleTagManager.auth,
        gtm_preview: environment.googleTagManager.env
      })
    ),
    provideAppCheck(() => {
      if (!environment.production) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN =
          environment.recaptcha.appCheckDebugToken;
      }
      const provider = new ReCaptchaEnterpriseProvider(
        environment.recaptcha.siteKey
      );
      return initializeAppCheck(undefined, {
        provider,
        isTokenAutoRefreshEnabled: true
      });
    })
  ]
};
