import { enableProdMode, mergeApplicationConfig } from '@angular/core';

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';

import { appConfig } from './app/config/app.config';
import { browserConfig } from './app/config/app.config.browser';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const config = mergeApplicationConfig(appConfig, browserConfig);

bootstrapApplication(AppComponent, config).catch((err) =>
  console.error({ err: 'error bootstraping app' + err })
);
